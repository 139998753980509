<template>
  <div>
    <div class="filter-section">
      <div class="filter-options">
        <div v-for="p in propertyTypes" :key="p.value" class="custom-option" :class="{ 'active': selectedType === p.value }"
            :style="{ backgroundColor: dynamicBackgroundColor[p.value] }"
            @mouseover="setDynamicBackgroundColor(p)" @mouseout="resetDynamicBackgroundColor(p)" @click="selectType(p.value, p.label)">
            {{ $t(p.label) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propertyTypesBackgroundColor: {
      type: String,
      default: 'white', // Provide a default color if not provided
    },
  },
  data() {
    return {
      selectedType: null,
      selectedLabel: null,
      propertyTypes: [
        { label: 'All', value: 0 },
        { label: 'Single Family', value: 1 },
        { label: 'Multiple Family', value: 5 },
        { label: 'Townhome', value: 2 },
        { label: 'Condo', value: 3 },
        { label: 'Penthouse', value: 4 },
      ],
      dynamicBackgroundColor: Array(6).fill('white'), // Initialize with white for each property type
    };
  },
  methods: {
    selectType(value, label) {
      this.selectedType = value;
      this.selectedLabel = label;
      this.$emit('property-type-selected', value, label);
    },
    setDynamicBackgroundColor(p) {
      this.$set(this.dynamicBackgroundColor, p.value, this.propertyTypesBackgroundColor);
    },
    resetDynamicBackgroundColor(p) {
      this.$set(this.dynamicBackgroundColor, p.value, 'white');
    },
  },
};
</script>

<style scoped>
.filter-section {
  margin-bottom: 15px;
}

.custom-option {
  padding: 5px 10px;
  cursor: pointer;
}

.active {
  /* Add styles for the active state if needed */
  font-weight: bold;
}
</style>
