<template>
  <div id="app">
    <yurekai-neo-map-base-search marker-clicked="getInfoWindowContent" apikey="AIzaSyDtSCVEZsZzdPCXCG7Vx1Ein4OAH5aCDjc"
      version="weekly" libraries="places,geometry,visualization,drawing,streetView" userid="123456">
    </yurekai-neo-map-base-search>
  </div>
</template>

<script>

import YurekaiNeoMapBaseSearch from './components/YurekaiNeoMapBaseSearch.vue';

export default {
  name: 'App',
  components: {
    YurekaiNeoMapBaseSearch
  }
}
</script>

<style></style>
