<!-- BedBathSelection.vue -->
<template>
    <div>
        <div class="filter-section">
            <div class="filter-title">{{$t('Number of Beds')}}</div>
            <select v-model="selectedMinBed" @change="emitSelection">
                <option value="">No min</option>
                <option v-for="option in bedOptions" :key="option" :value="option">{{ option }}</option>
            </select>
            <select v-model="selectedMaxBed" @change="emitSelection">
                <option value="">No max</option>
                <option v-for="option in bedOptions" :key="option" :value="option">{{ option }}</option>
            </select>
        </div>
        <div class="filter-section">
            <div class="filter-title">{{$t('Number of Baths')}}</div>
            <select v-model="selectedMinBath" @change="emitSelection">
                <option value="">No min</option>
                <option v-for="option in bathOptions" :key="option" :value="option">{{ option }}</option>
            </select>
            <select v-model="selectedMaxBath" @change="emitSelection">
                <option value="">No max</option>
                <option v-for="option in bathOptions" :key="option" :value="option">{{ option }}</option>
            </select>
        </div>
        <button @click="applyFilter($event)" :style="{ backgroundColor: bedBackgroundColor}" class="view-details">{{$t('Apply Filter')}}</button>
    </div>
</template>
  
<script>
export default {
    props: {
        bedOptions: {
            type: Array,
            required: true
        },
        bathOptions: {
            type: Array,
            required: true
        },
        bedBackgroundColor: {
            type: String
        }
    },
    data() {
        return {
            selectedMinBed: '',
            selectedMaxBed: '',
            selectedMinBath: '',
            selectedMaxBath: ''
        };
    },
    methods: {
        emitSelection() {
            console.log('emit selection');
            this.$emit('selection', {
                minBed: this.selectedMinBed,
                maxBed: this.selectedMaxBed,
                minBath: this.selectedMinBath,
                maxBath: this.selectedMaxBath
            });
        },
        applyFilter(event) {
            event.preventDefault();
            this.$emit('applyFilter', {
                minBed: this.selectedMinBed,
                maxBed: this.selectedMaxBed,
                minBath: this.selectedMinBath,
                maxBath: this.selectedMaxBath
            });
        },
    }
};
</script>
  
<style>
.filter-section {
    margin-bottom: 15px;
}


.filter-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.filter-options button {
    margin-right: 5px;
    margin-bottom: 5px;
}

.filter-options button {
    margin-right: 5px;
    margin-bottom: 5px;
}

.filter-btn {
    background-color: #f7f7f7;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 5px 10px;
}

.filter-btn.active {
    background-color: #FF6C00;
    color: white;
}

input,
button,
select {
    font-size: .95rem;
    border: 1px solid #888;
    padding: 2px 32px 2px 12px;
    border-radius: 6px;
    height: 40px
}

.view-details {
    background-color: #FF6C00;
    color: white;
    width: 100%;
    border: none;
    text-align: center;
}

.view-details:hover {
    background-color: #fb946e;
    color: white;
}
</style>
  