<template>
    <div class="container-fluid mt-2">
        <!-- Button toggle component -->
        <div class="row sticky-filters">
            <div class="col-12">
                <button v-if="showButtonMap" :style="mergedButtonStyle" @click="toggleComponent">
                    {{ $t('buttonlabel') }}
                </button>
            </div>
        </div>

        <!-- Filters -->
        <div class="sticky-filter">
            <div v-if="showDivComponent" class="row mt-3 sticky-filters">
                <div class="col-sm-6 col-md-6 col-lg-2 mb-2">
                    <div class="search-container">
                        <select @change="applyFilters" v-model="filters.state" id="state-select" class="form-control">
                            <option value="" disabled selected>{{ $t('State') }}</option>
                            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                        </select>
                        <i class="bi bi-search search-icon"></i>
                    </div>
                    <div class="btn-x">
                        <span class="btn clear-button" @click="clearFilters">
                            X
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-2 mb-2">
                    <div class="search-container">
                        <input @change="onChangeLocation" v-model="filters.address" id="address-input" type="text" class="form-control"
                            :placeholder="$t('Location')">
                        <i class="bi bi-search search-icon"></i>

                    </div>
                    <div class="btn-x">
                        <span class="btn clear-button" @click="clearAddress">
                            X
                        </span>
                    </div>

                </div>

                <div class="col-sm-6 col-md-6 col-lg-2 mb-2">
                    <button class="form-select price" @click="toggleDivPrice($event, 'price')">
                        {{ getPriceButtonText }}
                    </button>

                    <div v-if="divVisibility.price" class="filter-dropdown">

                        <div class="filter-section">
                            <div class="slider">
                                <input type="range" class="form-range" min="0" max="9000000" v-model="filters.minPrice"
                                    @input="validateMin" id="minRange">
                                <input type="range" class="form-range" min="0" max="9000000" v-model="filters.maxPrice"
                                    @input="validateMax" id="maxRange">
                            </div>
                        </div>
                        <div class="filter-section">
                            <div class="d-flex  justify-content-between mt-4" style="font-size: 0.90em;">
                                <span>Min: {{ filters.minPrice | formatPrice }}</span>
                                <span>Max: {{ filters.maxPrice | formatPrice }}</span>
                            </div>
                        </div>
                        <button @click="applyFilters" :style="{backgroundColor: mergedButtonStyle.backgroundColor}" class="view-details mt-1">{{$t('Apply Filter')}}</button>
                    </div>

                </div>

                <div class="col-sm-6 col-md-6 col-lg-2">
                    <button class="form-select bedbath" @click="toggleDivBedbath($event, 'bedbath')">
                        {{ getBedBathButtonText }}
                    </button>
                    <div v-if="divVisibility.bedbath" class="filter-dropdown">
                        <bed-bath-selection :bed-background-color="mergedButtonStyle.backgroundColor" :bed-options="bedroomOptions" :bath-options="bathroomOptions"
                            @selection="selectOptionsForBedAndBath" @applyFilter="applyFilters" />
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-2">
                    <button class="form-select propertyType" @click="toggleDivPropertyType($event, 'propertyType')">
                        {{ getPropertyTypeButtonText }}
                    </button>
                    <div v-if="divVisibility.propertyType" class="filter-dropdown">
                        <property-type :property-types-background-color="'lightgrey'" @property-type-selected="handleSelectedPropertyType"></property-type>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-1 mb-2">
                    <button class="view-details-g p-0" :style="{backgroundColor: filters.isQuickMoveIn ? mergedButtonStyle.backgroundColor : '', borderColor: filters.isQuickMoveIn ? mergedButtonStyle.backgroundColor : ''}" :class="{ 'active-movein': filters.isQuickMoveIn }"
                        @click="toggleMoveInDate">
                        {{$t('Quick Move-in')}}
                    </button>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-1 mb-2">
                    <button @click="clearFilters" 
                    :style="{backgroundColor: mergedButtonStyle.backgroundColor}" 
                    class="view-details"> {{$t('Clear')}}</button>
                </div>

            </div>
        </div>
        <!-- end filters -->


        <div class="row mt-3">
            <!-- Column for Cards -->
            <div class="col-sm-12 col-md-12 col-lg-7 scrool">

                <div v-if="!isLoading" class="row scrollable-cards">
                    <!-- div no project found -->
                    <div v-if="projectsInCards.length === 0 && showDivComponent" class="col-sm-12 col-md-12 col-lg-12">
                        <div class="text-center" v-html="$t('No projects found')">
                        </div>
                    </div>
                    <div class="scroll-container">
                        <div class="scroll-content" ref="scrollContainer" @scroll="handleScroll">
                            <div class="row mt-3">
                                <!-- v-for loop for cards -->
                                <div class="col-sm-12  col-md-6-custom col-lg-4" v-for="p in projectsInCards" :key="p.id"
                                    @click="goToDetailsPage(p.projectId || p._id, p.slugTitle)">
                                    <div class="card mb-3">
                                        <img class=" card-img card-img-top" :src="p.picture" alt="Home image">
                                        <div class="card-body text-center">
                                            <div>
                                                <span :style="{color: mergedButtonStyle.backgroundColor}" class="orange-neo-text"> {{ p.projectType }}</span>
                                            </div>
                                            <div class="card-text">{{ p.title }}</div>
                                            <h6 class="card-text">{{ p.builderName || p.builder }}</h6>
                                            <div>
                                                <span :style="{color: mergedButtonStyle.backgroundColor}" class="orange-neo-text"> {{ p.city }}, {{ p.state }}</span>
                                            </div>
                                            <strong>
                                                <h5 class="card-title" v-if="Number(p.price) !== 0"><strong>From ${{
                    Number(p.price).toLocaleString() }}</strong></h5>
                                            </strong>
                                            <hr>
                                            <div>{{ $t(p.status) }} </div>
                                            <div v-if="checkIfIsQuickMoveIn(p)" :style="{color: mergedButtonStyle.backgroundColor, borderColor: mergedButtonStyle.backgroundColor}" class="quick-movein-label"> {{$t('quick move-in available')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="showDivComponent" class="row" style="margin-top: 3px">
                    <div class="col-sm-12 col-lg-12 text-center">
                        <button v-if="showLoadMoreButton" @click="loadMore" :style="mergedButtonStyle">{{$t('Load More')}}</button>
                    </div>
                </div>

                <!-- Loader -->
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div v-if="isLoading" class="loader-container">
                            <div class="loader-content">
                                <!--<img :style="{ fill: mergedButtonStyle.backgroundColor }" :src="loaderSvg" alt="Loading...">-->
                                <svg class="lds-spinner" width="200px"  height="200px"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;"><g transform="rotate(0 50 50)">
                                <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                                </rect>
                                </g><g transform="rotate(30 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(60 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(90 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(120 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(150 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(180 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(210 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(240 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(270 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(300 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g><g transform="rotate(330 50 50)">
                                  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" :style="{ fill: mergedButtonStyle.backgroundColor }">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                                  </rect>
                                </g></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Column for Map -->
            <div class="col-sm-12 col-md-12 col-lg-5 mb-3">
                <div ref="map" style="width: 100%; height: 90vh;"></div>
            </div>

        </div>

    </div>
</template>

<script>
/* eslint-disable */
/* global google */ //Do not remove this line beacuse it needs to be global for the google maps api to work
const { Loader } = require("@googlemaps/js-api-loader");
import loaderSvg from '../assets/img/loader.svg';
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { getProjects, getFilteredProjects, getStateForAgent } from "@/requestWrapper.js";
import BedBathSelection from "@/components/BedBathSelection.vue";
import PropertyType from "@/components/PropertyType.vue";

export default {
    name: "YurekaiNeoMapBaseSearch",
    props: {
        apikey: {
            type: String,
            required: true
        },
        version: {
            type: String,
            default: 'weekly' //to discuss 
        },
        libraries: {
            type: String,
            default: ''
        },
        initiallatitude: {
            type: String,
            default: "27.9944024" // Default latitude for Florida
        },
        initiallongitude: {
            type: String,
            default: "-81.7602544" // Default longitude for Florida
        },
        initialzoom: {
            type: String,
            default: "6" // Default zoom level
        },
        buttonlabel: {
            type: String,
            default: 'Search By Map'
        },
        custombuttonstyle: {
            type: Object,
            default: () => ({})
        },
        userid: {
            type: String,
            default: ''
        },
        lang: {
            type: String,
            default: 'us'
        },
        urlprojects: {
            type: String,
            default: 'https://www.newestateonly.com/{lang}project/'
        },
        agentid: {
            type: String,
            default: ''
        },
        state: {
            type: String,
            default: ''
        }
    },
    components: {
        BedBathSelection,
        PropertyType
    },
    data() {
        return {
            isLoading: false,
            defaultbuttonstyle: {
                backgroundColor: "#FF6C00",   // Orange background
                color: "white",               // White text color
                border: "none",               // No border
                borderRadius: "25px",         // Rounded corners
                padding: "10px 20px",         // Padding for size
                fontSize: "16px",             // Font size
                fontWeight: "bold",           // Bold text
                cursor: "pointer",            // Hand cursor on hover
                outline: "none",              // No outline on focus
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"  // Slight shadow for depth
            },
            loaderSvg,
            showDivComponent: false,

            projectsInCards: [],
            allProjects: [],

            isLocationLocked: false,
            filters: {
                limit: 4000,
                state: '',
                address: '',
                lat: '',
                lng: '',
                noOfBeds: '',
                noOfBath: '',
                maxNoOfBeds: '',
                maxNoOfBath: '',
                zipCode: '',
                addressType: '',
                distance: 48280,
                sortByName: '',
                type: '',
                minPrice: 0,
                maxPrice: 9000000,
                isQuickMoveIn: false,
                propertyType: '',
                agentId: ''
            },

            divVisibility: {
                price: false,
                bedbath: false,
                propertyType: false
            },
            states: ["Florida", "Texas"],
            selectedType: '',
            showLoadMoreButton: true,
            bedroomOptions: ['Studio', '1', '2', '3', '4', '5'],
            bathroomOptions: ['1', '2', '3', '4', '5'],
            defaultUrlProjects: 'https://www.newestateonly.com/{lang}project/',
            acceptedOrigins: ['https://www.newestateonly.com', 'https://staging.newestateonly.com', 'https://staging-plus.newestateonly.com'],

            map: null,
            markers: [],
            selectedLabelForPropertyType: '',
            showButtonMap: true,
            place: null
        };
    },

    methods: {
        onChangeLocation() {
            // when user selects a certain location this remains locked even when 
            // user interacts with the map (zoom, drag, ...). 
            // to unlock the address the user has to remove manually the filter location
            this.isLocationLocked = true;
            // console.log('Location locked');
        },
        toggleDiv(event, divKey, buttonClass) {
            event.preventDefault();
            this.divVisibility[divKey] = !this.divVisibility[divKey];
            const outsideClickHandler = (event) => {
                const dropdownElement = this.$el.querySelector('.filter-dropdown');
                const button = this.$el.querySelector(buttonClass);
                if (dropdownElement !== null) {
                    if (!dropdownElement.contains(event.target) && event.target !== button) {
                        this.divVisibility[divKey] = false;
                        document.removeEventListener('click', outsideClickHandler);
                    }
                }
            }
            if (this.divVisibility[divKey]) {
                document.addEventListener('click', outsideClickHandler);
            }
        },
        toggleDivPrice(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.price');
        },
        toggleDivBedbath(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.bedbath');
        },

        toggleDivPropertyType(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.propertyType');
        },
        async fillStateFilter() {
            if (this.filters.agentId) {
                let states = await getStateForAgent(this.filters.agentId)
                this.states = states?.result
                this.filters.state = this.states[0] || '';
            }
        },
        async toggleComponent() {
            this.isLoading = true;
            let allProjects = await this.getAllProjects(this.filters);
            this.allProjects = allProjects.result; // projects to be used for map
            // Slice the first six elements for filteredProjects and projectsInCards
            let slicedProjects = this.allProjects.slice(0, 6);
            this.projectsInCards = slicedProjects;
            await this.createMap(allProjects, this.initiallatitude, this.initiallongitude, this.initialzoom);
            this.showButtonMap = false;
            this.isLoading = false;
            this.showDivComponent = !this.showDivComponent;
            return;
        },

        async getLimitedFilteredProjects(filters) {
            this.isLoading = true;
            let response = await getFilteredProjects(filters) || [];
            // Concatenate the new projects with existing ones
            this.projectsInCards = this.projectsInCards.concat(response.result);
            // Check if there are more projects to load
            this.showLoadMoreButton = response.result.length >= 6 || response.length == 0 ? true : false;
            this.isLoading = false;
            return response;
        },
        async getAllProjects(filters) {

            return new Promise(async (resolve, reject) => {
                try {
                    let response = await getFilteredProjects(filters) || [];
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        },
        /**
        * Go to details page.
        * On click on the info window, it will open a new tab with the details page.
        */
        goToDetailsPage(_id, slugTitle) {
            if(!this.urlprojects)
                this.urlprojects = this.defaultUrlProjects
            if(!this.acceptedOrigins.includes((new URL(decodeURIComponent(this.urlprojects))).origin))
                this.urlprojects = this.defaultUrlProjects
            let url = ''
            if(this.urlprojects.includes('landing')) {
                if (decodeURIComponent(this.urlprojects).includes('{idProgetto}'))
                    url = decodeURIComponent(this.urlprojects).replace(/{idProgetto}/g, _id)
                else
                   url = decodeURIComponent(this.urlprojects).replace(/=$/g, '') + "=" + _id
                url = `${this.urlprojects}?N_projectId=${_id}&N_type=project${ ["es", "pt"].includes(this.lang) ? `&lang=${this.lang}` : ''}`;
            } else {
                url = this.urlprojects.replace("{lang}", (["es", "pt"].includes(this.lang) ? `${this.lang}/` : ''))+slugTitle
            }
            console.log(url, _id)
            window.open(url, '_blank');
        },
        async computeQuickMoveInStatus(projects) {
            for (let i = 0; i < projects.length; i++) {
                projects[i].isQuickMoveIn = await this.checkIfIsQuickMoveIn(projects[i]);
            }
        },
        checkIfIsQuickMoveIn(project) {
            if (project.isQuickMoveIn) {
                return true;
            }
            if (project.zone && project.zone.buildings) {
                for (let i = 0; i < project.zone.buildings.length; i++) {
                    if (project.zone.buildings[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            if (project.homedesign) {
                for (let i = 0; i < project.homedesign.length; i++) {
                    if (project.homedesign[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            if (project.zone && project.zone.residences) {
                for (let i = 0; i < project.zone.residences.length; i++) {
                    if (project.zone.residences[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            if (project.zone && project.level2Residences) {
                for (let i = 0; i < project.level2Residences.length; i++) {
                    if (project.level2Residences[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            return false;
        },
        async loadMore() {
            this.isLoading = true;
            const savedScrollPosition = localStorage.getItem('scrollPosition');
            if (savedScrollPosition) {
                this.$refs.scrollContainer.scrollTop = +savedScrollPosition + (this.showLoadMoreButton ? 10 : 0);
            }
            const currentIndex = this.projectsInCards.length;
            const nextProjects = this.allProjects.slice(currentIndex, currentIndex + 6);
            if (nextProjects.length > 0) {
                this.projectsInCards = [...this.projectsInCards, ...nextProjects];
            } else {
                this.showLoadMoreButton = false;
            }
            this.isLoading = false;
        },

        async clearFilters() {
            this.filters.state = '';
            this.filters.address = '';
            this.filters.lat = '';
            this.filters.lng = '';
            this.filters.zipCode = '';
            this.filters.addressType = '';
            this.filters.sortByName = '';
            this.filters.type = '';
            this.filters.minPrice = 0;
            this.filters.maxPrice = 9000000;
            this.filters.noOfBeds = '';
            this.filters.noOfBath = '';
            this.filters.maxNoOfBeds = '';
            this.filters.maxNoOfBath = '';
            this.filters.isQuickMoveIn = false;
            this.filters.propertyType = '';
            this.selectedLabelForPropertyType = '';

            this.isLocationLocked = false;

            await this.refreshData(parseFloat(this.initiallatitude), parseFloat(this.initiallongitude), parseFloat(this.initialzoom));
        },
        clearSomeFilters() {
            this.isLocationLocked = false;
            this.filters.address = '';
            this.filters.lat = '';
            this.filters.lng = '';
            this.filters.zipCode = '';
            this.filters.addressType = '';
            this.filters.sortByName = '';
        },
        async clearAddress() {
            this.filters.address = '';
            let lat = this.filters.lat
            let lng = this.filters.lng
            this.filters.lat = '';
            this.filters.lng = '';

            this.isLocationLocked = false;

            await this.refreshData(lat, lng, this.map.getZoom());
        },
        async applyFilters() {
            this.divVisibility.price = false;
            this.divVisibility.bedbath = false;
            this.divVisibility.propertyType = false;

            if (this.filters.state == '') {
                this.filters.state = 'Florida';
                await this.refreshData(parseFloat(this.initiallatitude), parseFloat(this.initiallongitude), parseInt(this.initialzoom));
                this.filters.state = '';
                return;
            }

            if (this.filters.address !== '') {
                let stateFromAddress = this.searchForStateAfterAddressInput(this.place);
                if (this.filters.state !== stateFromAddress) {
                    this.clearSomeFilters();
                }
            }
            let dataLocation = await this.getLatAndLngFromState(this.filters.state);
            await this.refreshData(dataLocation.lat, dataLocation.lng, 7);
        },
        async refreshData(lat, lng, zoom) {
            this.isLoading = true;
            let all = await this.getAllProjects(this.filters);
            if (all && all.result && all.result.length > 0) {
                // Slice the first six elements for projectsInCards
                let slicedProjects = all.result.slice(0, 6);
                this.projectsInCards = slicedProjects;
                this.allProjects = all.result;
                this.allProjects.length > 6 ? this.showLoadMoreButton = true : this.showLoadMoreButton = false;
            } else {
                this.allProjects = [];
                this.projectsInCards = [];
                this.showLoadMoreButton = false;
                this.clearMarkers();
                console.log("No projects found.");

            }
            await this.createMap(this.allProjects, lat, lng, 7);

            this.isLoading = false;
        },
        async refreshDataAfterMapEventTriggered(lat, lng, zoom, zoomChanged) {

            if (this.isLocationLocked) return;
            
            this.isLoading = true;

            try {
                // if (!zoomChanged) {
                let results = await this.getStateAndCityFromLatAndLng(parseFloat(lat), parseFloat(lng));
                console.log(`User moved to ${results.city}, ${results.state}`);
                this.filters.state = results.state;
                this.filters.address = results.city;
                // }
                this.filters.lat = parseFloat(lat);
                this.filters.lng = parseFloat(lng);
                this.calculateVisibleRadius();
                let all = await this.getAllProjects(this.filters);

                if (all && all.result && all.result.length > 0) {
                    let slicedProjects = all.result.slice(0, 6);
                    this.projectsInCards = slicedProjects;
                    this.allProjects = all.result;
                    this.allProjects.length > 6 ? this.showLoadMoreButton = true : this.showLoadMoreButton = false;
                    console.log(this.allProjects.length + " projects found")
                } else {
                    this.allProjects = [];
                    this.projectsInCards = [];
                    this.showLoadMoreButton = false;
                    this.clearMarkers();
                    console.log("No projects found.");
                }
                await this.createMap(this.allProjects, lat, lng, zoom);

                this.isLoading = false;
            } catch (error) {
                console.error(error);
                this.isLoading = false;
            }
        },

        // Generic initialization for autocomplete
        initializeAutocomplete(inputId, updateCallback) {
            const inputElement = document.getElementById(inputId);

            if (inputElement) {
                const autocomplete = new google.maps.places.Autocomplete(inputElement, {
                    componentRestrictions: { 'country': ['us'] }
                });
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    updateCallback(place);
                });
            }
        },
        async handleAddressInput(place) {
            if (!place.geometry) {
                console.log("No details available for input: '" + place.name + "'");
                return;
            }
            this.filters.lat = place.geometry.location.lat();
            this.filters.lng = place.geometry.location.lng();
            this.filters.state = this.searchForStateAfterAddressInput(place);
            this.filters.address = this.searchForAddress(place);
            this.place = place;
            this.filters.addressType = place.types[0] == 'locality' ? 'locality' : 'addressType';
            if (this.filters.address == '') {
                this.clearSomeFilters();
                let dataLocation = await this.getLatAndLngFromState(this.filters.state);
                return await this.refreshData(dataLocation.lat, dataLocation.lng, 7);
            }
            await this.refreshData(this.filters.lat, this.filters.lng, this.initialzoom);

        },
        searchForStateAfterAddressInput(place) {
            const stateComponent = place.address_components.find(component => component.types.includes('administrative_area_level_1'));
            if (stateComponent) {
                return stateComponent.long_name;
            }
            return '';
        },
        searchForAddress(place) {
            let address = '';
            const localityComponent = place.address_components.find(component => component.types.includes('locality') || component.types.includes('colloquial_area'));
            if (localityComponent) {
                address = localityComponent.long_name;
                return address;
            }
            return '';
        },
        validateMin() {
            if (this.filters.minPrice > this.filters.maxPrice) {
                this.filters.minPrice = this.filters.maxPrice;
            }
        },
        validateMax() {
            if (this.filters.maxPrice < this.filters.minPrice) {
                this.filters.maxPrice = this.filters.minPrice;
            }
        },
        selectOptionsForBedAndBath(selection) {
            if (selection.minBed == 'Studio') {
                selection.minBed = 0;
            }
            if (selection.maxBed == 'Studio') {
                selection.maxBed = 0;
            }
            this.filters.noOfBeds = selection.minBed;
            this.filters.noOfBath = selection.minBath;
            this.filters.maxNoOfBeds = selection.maxBed;
            this.filters.maxNoOfBath = selection.maxBath;
        },
        handleSelectedPropertyType(type, label) {
            if (type == 0) {
                type = '';
            }
            this.selectedLabelForPropertyType = label;
            this.filters.type = type;
            this.applyFilters();
        },
        toggleMoveInDate() {
            this.filters.isQuickMoveIn = !this.filters.isQuickMoveIn;
            this.applyFilters();
        },
        // MAP METHODS
        async createMap(projects, lat, lng, zoom) {
            lat = parseFloat(lat);
            lng = parseFloat(lng);
            zoom = parseInt(zoom);
            this.map = new google.maps.Map(this.$refs.map, {
                center: { lat, lng },
                zoom,
                maxZoom: 18
            });


            await this.loadMarkers(projects);
            this.addMapEventListeners();
        },
        async loadMarkers(projects) {
            this.markers = [];
            try {
                if (projects.result) {
                    projects = projects.result;
                }
                projects = this.slightlyChangeCoordinates(projects)
                for (const project of projects) {
                    await this.createSingleMarker(project);
                }
                if (this.map.getZoom() < 18)
                    await this.createCluster();

            } catch (error) {
                console.error('Error while loading markers:', error);
            }
        },
        async createSingleMarker(project) {

            const marker = new google.maps.Marker({
                position: { lat: project.location.coordinates[1], lng: project.location.coordinates[0] },
                map: this.map,
            });

            marker.addListener('click', () => {
                this.infoWindow && this.infoWindow.close();
                this.infoWindow = new google.maps.InfoWindow();
                this.infoWindow.setContent(this.getInfoWindowContent(project));
                this.infoWindow.open(this.map, marker);
                //this.getAddressFromLatLng(marker.getPosition()) TODO:: FOR AGENTS
            });

            this.markers.push(marker);
        },
        createCluster() {
            const cluster = new MarkerClusterer(this.map, this.markers, {
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                imageExtension: 'png',
            });

            google.maps.event.addListener(cluster, 'clusterclick', (cluster) => {
                console.log('cluster clicked');
                //console.log(cluster.getMarkers());
            });

            return cluster;
        },
        destroyMapAndMarkers() {
            this.markers.forEach(marker => {
                marker.setMap(null);
            });
            this.markers = [];
            this.map = null;
        },
        async clearMarkers() {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = [];
        },
        slightlyChangeCoordinates(projects) {
            const coordMap = {}
            
            projects.forEach(project => {
                const coord = project.location.coordinates.join(',')
                if (!coordMap[coord])
                    coordMap[coord] = 0
                coordMap[coord]++;
            })

            const allSameCoordinates = Object.keys(coordMap).length === 1 && coordMap[Object.keys(coordMap)[0]] === projects.length

            if (allSameCoordinates) {
                let first = true
                projects.forEach((project, index) => {
                    if (first) {
                        first = false
                        console.log("slightlyChangeCoordinates of " + (projects.length-1) + " projects")
                    } else {
                        const [lon, lat] = project.location.coordinates;
                        const adjustment = (Math.random() * 0.001 - 0.0005);
                        const newLat = lat + adjustment;
                        project.location.coordinates = [lon, newLat];
                    }
                })
            }

            return projects
        },
        async getLatAndLngFromState(state) {
            return new Promise((resolve, reject) => {
                const geocoder = new google.maps.Geocoder();

                geocoder.geocode({ address: state }, (results, status) => {
                    if (status === 'OK') {
                        const location = results[0].geometry.location;
                        const lat = location.lat();
                        const lng = location.lng();
                        resolve({ lat, lng });
                    } else {
                        reject(`Geocoding failed with status: ${status}`);
                    }
                });
            });
        },
        addMapEventListeners() {
            // Track zoom level changes
            this.map.addListener('zoom_changed', async () => {
                const bounds = this.map.getBounds();
                this.calculateVisibleRadius();
                console.log('zoom changed', this.map.getZoom(), this.filters.distance);
                this.refreshDataAfterMapEventTriggered(bounds.getCenter().lat(), bounds.getCenter().lng(), this.map.getZoom(), true);
            }, { passive: true });

            // Track map drag
            this.map.addListener('dragend', async () => {
                console.log('map dragged');
                const bounds = this.map.getBounds();
                this.refreshDataAfterMapEventTriggered(bounds.getCenter().lat(), bounds.getCenter().lng(), this.map.getZoom());
            });
            //cluster click
            this.map.addListener('clusterclick', async () => {
                console.log('cluster clicked');
                const bounds = this.map.getBounds();
                this.refreshDataAfterMapEventTriggered(bounds.getCenter().lat(), bounds.getCenter().lng(), this.map.getZoom());
            });
        },
        calculateVisibleRadius() {
            const bounds = this.map.getBounds();
            const center = this.map.getCenter();
            
            // Get midpoints of the bounds
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();

            // Calculate midpoints
            const midNorth = new google.maps.LatLng(northEast.lat(), center.lng());
            const midEast = new google.maps.LatLng(center.lat(), northEast.lng());
            const midSouth = new google.maps.LatLng(southWest.lat(), center.lng());
            const midWest = new google.maps.LatLng(center.lat(), southWest.lng());

            // Choose one of the midpoints to calculate the radius
            const radius = this.calculateDistance(center, midNorth);
            this.filters.distance = radius;
            return radius;
        },
        calculateDistance(point1, point2) {
            const R = 6371000; // Radius of the Earth in meters
            const lat1 = point1.lat() * Math.PI / 180;
            const lat2 = point2.lat() * Math.PI / 180;
            const deltaLat = (point2.lat() - point1.lat()) * Math.PI / 180;
            const deltaLng = (point2.lng() - point1.lng()) * Math.PI / 180;

            const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                      Math.cos(lat1) * Math.cos(lat2) *
                      Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const distance = R * c;
            return distance;
        },
        getInfoWindowContent(project) {
            let formattedPrice = project.price ? Number(project.price).toLocaleString() : null;
            let stringPrice = formattedPrice ? `<h3 style="color: #8b8b8b; font-size: 18px; font-weight: 400">From $${formattedPrice}</h3>` : '';
            let slugTitle = project.slugTitle;
            let projectId = project.projectId || project._id;
            //console.log("project", project)
            let isQuickMoveIn = this.checkIfIsQuickMoveIn(project);
            let labelOrange = '';
            if (isQuickMoveIn) {
                labelOrange = `<div style="
                                    border: 1px solid ${this.mergedButtonStyle.backgroundColor}; 
                                    color: ${this.mergedButtonStyle.backgroundColor};
                                    font-size: 13px;
                                    padding: 4px 11px; 
                                    margin-top: 5px;
                                    display: inline-block; 
                                    text-transform: uppercase">
                                    ${this.$t('quick move-in available')}
                                </div>`
            } else {
                labelOrange = '';
            }
            //console.log("project", project)
            return `
                    <div class="info-window-class" style="display: flex; width: 100%; font-family: 'GillSans-SemiBold', sans-serif; background-color: #e8e8e8" data-slug-title="${slugTitle}" data-project-id="${projectId}"> 
                        <div style="flex: 1; padding: 5px; text-align: center;">
                            <p style="color: ${this.mergedButtonStyle.backgroundColor}; font-size: 15px;">${project.projectType}</p> 
                            <div style="color: #8b8b8b; font-size: 18px; font-weight: 600">${project.title}</div> 
                            <span style="color: #8b8b8b; font-size: 18px; font-weight: 600">${project.builderName || project.builder}</span>
                            <p style="color: ${this.mergedButtonStyle.backgroundColor}; font-size: 20px; margin-bottom: 8px; font-weight: 600">${project.location.city}, ${project.location.state}</p> 
                             ${stringPrice}
                            <hr style="border: 1px solid #C0C0C0; margin: 16px 0;">
                            <h3 style="color: #A9A9A9; font-size: 15px;">${this.$t(project.status)}</h3>
                            ${labelOrange}
                        </div>
                        <div style="flex: 1; margin: 5px; width: 193px; background-image: url('${project.picture}'); background-position: center; background-repeat: no-repeat; background-size: cover;">
                            <!--<img src="${project.picture}" alt="${project.title}" style="width: 100%; height: auto;">-->
                        </div>
                    </div>
                    
                `;
        },
        clearStateAndAddress() {
            //this.filters.limit = 6;
            this.filters.state = '';
            this.filters.address = '';
        },
        clearBathBedAndPrices() {
            this.filters.noOfBeds = '';
            this.filters.noOfBath = '';
            this.filters.maxNoOfBeds = '';
            this.filters.maxNoOfBath = '';
            this.filters.minPrice = 0;
            this.filters.maxPrice = 9000000;
        },
        getAddressFromLatLng(latlng) {
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ 'location': latlng }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        this.setAddressToDestinationInput(results[0].formatted_address);
                    } else {
                        console.error('No results found');
                    }
                } else {
                    console.error('Geocoder failed due to: ' + status);
                }
            });
        },
        async getStateAndCityFromLatAndLng(lat, lng) {
            return new Promise((resolve, reject) => {
                const geocoder = new google.maps.Geocoder();

                geocoder.geocode({ location: { lat, lng }}, (results, status) => {
                    if (status === 'OK') {
                        let state = '';
                        let city = '';

                        for (const result of results) {
                            const stateComponent = result.address_components.find(component => {
                                return component.types.includes('administrative_area_level_1');
                            });

                            const cityComponent = result.address_components.find(component => {
                                return component.types.includes('locality') || component.types.includes('colloquial_area');
                            });

                            if (stateComponent) {
                                state = Array.isArray(stateComponent) ? stateComponent[0].long_name || '' : stateComponent.long_name || '';
                            }

                            if (cityComponent) {
                                city = Array.isArray(cityComponent) ? cityComponent[0].long_name || '' : cityComponent.long_name || '';
                            }

                            if (state && city) {
                                resolve({ state, city });
                                this.place = { address_components: result.address_components }
                                return;
                            }
                        }
                        reject('State and/or city information not found.');
                    } else {
                        reject(`Reverse geocoding failed with status: ${status}`);
                    }
                });
            });
        },
        handleScroll() {
            localStorage.setItem('scrollPosition', this.$refs.scrollContainer.scrollTop);
        },
        parseQs() {
            let qs = decodeURI(location.search)
            qs = qs.replace('?', '')
            if (qs == '') 
                qs = this.getItemWithExpiration('qs') || ''
            qs.split("&").forEach(pair => {
                let [k, v] = pair.split('=')
                console.log(k,v)
                if (Object.keys(this.$props).includes(k)) {
                    this[k] = decodeURIComponent(v)
                    if (k == 'agentid')
                        this.filters.agentId = v.split('?')[0]
                }
                if (Object.keys(this.defaultbuttonstyle).includes(k)) {
                    this.custombuttonstyle[k] = decodeURIComponent(v)
                    this.mergedButtonStyle[k] = decodeURIComponent(v)
                }
                if (Object.keys(this.defaultbuttonstyle).map(el => 'custombuttonstyle['+el+']').includes(k)) {
                    let [k0, k1] = k.split(/\[|\]/).filter(Boolean)
                    if(!this[k0])
                        this[k0] = {}
                    this[k0][k1] = decodeURIComponent(v)
                    this.mergedButtonStyle[k1] = decodeURIComponent(v)
                }
            })
            if (location.search.replace('?', '') != '')
                this.setItemWithExpiration('qs', qs, 1)
            window.history.pushState({}, '', '/')
        },
        setItemWithExpiration(key, value, expirationInDays) {
            const expirationMs = expirationInDays * 24 * 60 * 60 * 1000; // Convert days to milliseconds
            const expirationTime = new Date().getTime() + expirationMs;
            const item = {
                value: JSON.stringify(value),
                expirationTime: expirationTime
            };
            localStorage.setItem(key, JSON.stringify(item));
        },
        getItemWithExpiration(key) {
            const storedItem = localStorage.getItem(key);

            if (!storedItem) {
                return null;
            }

            const item = JSON.parse(storedItem);

            // Check if the data is expired
            if (new Date().getTime() > item.expirationTime) {
                // Data is expired, remove it
                localStorage.removeItem(key);
                return null;
            }

            return JSON.parse(item.value);
        }
    },
    filters: {
        formatPrice(value) {
            if (typeof +value !== 'number')
                return value
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            })
            return formatter.format(value)
        }
    },
    computed: {
        mergedButtonStyle() {
            return { ...this.defaultbuttonstyle, ...this.custombuttonstyle };
        },
        getPriceButtonText() {
            const defaultMinPrice = 0;
            const defaultMaxPrice = 9000000;

            if (
                (this.filters.minPrice !== defaultMinPrice) ||
                (this.filters.maxPrice !== defaultMaxPrice)
            ) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })
                const minPriceText = this.filters.minPrice ? `${formatter.format(this.filters.minPrice)}` : '0 $';
                const maxPriceText = this.filters.maxPrice ? `${formatter.format(this.filters.maxPrice)}` : '∞ $';

                return `${minPriceText} - ${maxPriceText}`;
            } else {
                return this.$t('Price');
            }
        },
        getPropertyTypeButtonText() {
            const selectedLabel = this.selectedLabelForPropertyType;
            if (selectedLabel) {
                return `${selectedLabel}`;
            } else {
                return this.$t('Property Type');
            }
        },
        getBedBathButtonText() {
            const defaultMinBed = '';
            const defaultMaxBed = '';
            const defaultMinBath = '';
            const defaultMaxBath = '';

            if (
                (this.filters.noOfBeds !== defaultMinBed) ||
                (this.filters.noOfBath !== defaultMinBath) ||
                (this.filters.maxNoOfBeds !== defaultMaxBed) ||
                (this.filters.maxNoOfBath !== defaultMaxBath)
            ) {
                const minBedText = this.filters.noOfBeds >= 0 ? `${this.filters.noOfBeds} Beds` : '0 Beds';
                const maxBedText = this.filters.maxNoOfBeds >= 0 ? `${this.filters.maxNoOfBeds} Beds` : '∞ Beds';
                const minBathText = this.filters.noOfBath ? `${this.filters.noOfBath} Baths` : '0 Baths';
                const maxBathText = this.filters.maxNoOfBath ? `${this.filters.maxNoOfBath} Baths` : '∞ Baths';

                return `${minBedText} - ${maxBedText} / ${minBathText} - ${maxBathText}`;
            } else {
                return this.$t('Bed/Bath');
            }
        }
    },
    watch: {
        lang(newVal, oldVal) {
            console.log("lang watcher", newVal, oldVal)
            this.$i18n.locale = newVal
        }
    },
    created() {
        document.addEventListener('click', (event) => {
            if (event.target.matches('.info-window-class') || event.target.closest('.info-window-class')) {
                this.goToDetailsPage(event.target.closest('.info-window-class').dataset.projectId, event.target.closest('.info-window-class').dataset.slugTitle);
            }
        });
    },
    mounted() {
        this.showButtonMap = false;
        console.log("YurekaiNeoMapBaseSearch mounted!");
        const librariesArray = this.libraries.split(',');
        const loader = new Loader({
            apiKey: this.apikey,
            version: this.version,
            libraries: librariesArray,
        });
        loader.load().then(async () => {

            // Initialize Autocomplete for each existing waypoint
            this.$nextTick(async () => {
                this.initializeAutocomplete('address-input', this.handleAddressInput);
                this.parseQs();
                await this.toggleComponent();
                await this.fillStateFilter();
                // this.waypoints.forEach((_, index) => {
                //     this.initializeAutocompleteForWaypoint(index);
                // });
            });
        });
    },
    updated() {
        this.$nextTick(() => {
            this.initializeAutocomplete('address-input', this.handleAddressInput);
        });
        
    },
    destroyed() {
        console.log("Component destroyed");
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300&display=swap');

* {
    font-family: 'Figtree', sans-serif;
}

.sticky-filter {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #fefefe;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    /* Opzionale: aggiunge un overlay semitrasparente */
    z-index: 9999;
    /* Assicura che il loader sia sempre sopra il resto del contenuto */
}

.loader-content {
    text-align: center;
    /* Opzionale: per allineare il contenuto del loader */
}


.scroll-container {
    overflow: hidden;
    position: relative;
}

.scroll-content {
    height: 80vh;
    overflow-y: scroll;
    padding-right: 17px;
}

.scroll-content::-webkit-scrollbar {
    width: 0;
}

.scroll-container:hover .scroll-content::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}


.search-container {
    position: relative;
}

.search-icon {
    position: absolute;
    left: 10px;

    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.form-control {
    padding-left: 30px;
    /* Adjust the padding to make space for the icon */
}

.clear-button {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust the value as needed to position the button */
    transform: translateY(-100%);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.btn-x {
    position: relative;
}

.card {
    border: 2px solid rgba(0, 0, 0, .12);
    border-radius: 8px;
    cursor: pointer;
    transition: all linear .2s;
}

.card:hover {
    border-color: #aaa;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .2);
}

.card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.view-details {
    background-color: #FF6C00;
    color: white;
    width: 100%;
    font-size: 13px;
    border: none;
    text-align: center;
    padding: 0;
}

.view-details-g {
    background-color: #888;
    border: #888;
    color: white;
    width: 100%;
    font-size: 13px;
    border: none;
    text-align: center;
}

.view-details:hover {
    background-color: #fb946e;
    color: white;
}

.view-details-g:hover {
    background-color: #bfb7b7;
    color: white;
}

.custom-slider {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.slider {
    position: relative;
}

.form-range {
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #e9ecef;
    outline: none;
    padding: 0;
    margin: 0;
    color: #FF6C00;
}

.form-range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    background: #FF6C00;
    cursor: pointer;
}

#minRange {
    z-index: 1;
}

#maxRange {
    z-index: 2;
}

.filter-dropdown {
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10;
}

.filter-section {
    margin-bottom: 15px;
}

.filter-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.filter-options button {
    margin-right: 5px;
    margin-bottom: 5px;
}

.filter-btn {
    background-color: #f7f7f7;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 5px 10px;
}

.filter-btn.active {
    background-color: #FF6C00;
    color: white;
}

.active-movein {
    background-color: #FF6C00;
    border: 1px solid #FF6C00;
    color: white;
}

input,
button,
select {
    font-size: .95rem;
    border: 1px solid #888;
    padding: 2px 32px 2px 12px;
    border-radius: 6px;
    height: 40px
}

.quick-movein-label {
    border: 1px solid #FF6C00;
    color: #FF6C00;
    font-size: 13px;
    padding: 4px 11px;
    margin-top: 5px;
    display: inline-block;
    text-transform: uppercase;
}

.card-body {
    width: 100% !important;
    height: 16em;

}

.orange-neo-text {
    color: #FF6C00;
    font-size: 18px;
    font-weight: 600;
}

.selected-type {
    margin-top: 10px;
    font-weight: bold;
}

.form-select.price {
    font-size: .80rem;
}

.form-select.bedbath {
    font-size: .80rem;
}

.scrool::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar {
    display: none;
}

@media (min-width: 700px) and (max-width: 1570px) {
    .col-md-6-custom {
        flex: 0 0 auto;
        width: 50%;
    }
}
</style>
